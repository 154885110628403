/* eslint no-unused-vars: 0 */
import React, {Component} from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import {stateOptions} from "./common"

import MaskedInput from 'react-maskedinput'
import DropZone from "react-dropzone"
import ReactTable from "react-table";
import "react-table/react-table.css";
import Moment from "react-moment"
import UserFields from "./UserFields"
import {VendorContactEdit} from "./VendorContactEdit"
import Tab from 'semantic-ui-react/dist/es/modules/Tab';
import Menu from 'semantic-ui-react/dist/es/collections/Menu';
import Label from 'semantic-ui-react/dist/es/elements/Label';
import Button from 'semantic-ui-react/dist/es/elements/Button';
import Icon from 'semantic-ui-react/dist/es/elements/Icon';
import Confirm from 'semantic-ui-react/dist/es/addons/Confirm';
import Grid from 'semantic-ui-react/dist/es/collections/Grid';
import global from "./global"
import {Form, Dropdown, Checkbox} from 'formsy-semantic-ui-react';
import Modal from 'semantic-ui-react/dist/es/modules/Modal';
import Header from 'semantic-ui-react/dist/es/elements/Header';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import {toast} from 'react-toastify';
import NumberFormat from 'react-number-format'
export class VendorEditRecord extends Component {

    constructor(props) {
        super(props);
        this.state = {
            access_token: this.props.access_token,
            recid: this.props.recid,
            data: this.props.data,
            users: this.props.users,
            dirtyFlag: this.props.dirtyFlag,
            confirmOpen: false,
            addldata: {},
            randomkey: Math.random(),
            randomkey2: Math.random(),
            randomkey3: Math.random(),

            showVendorContactModal: false,
            current_user: JSON.parse(sessionStorage.getItem("current_user")),
            user_fields: this.props.user_fields,
            showcontactmodal: false,
            data_vendor_contacts: this.props.data.rec.vendor_contacts


        };

    }

    handleSave = () => {

        this.refs.editForm.submit()

    }
    handleCancel = () => {
        this.setState({confirmOpen: false})
    }
    handleUndoConfirm = () => {
        this.setState({confirmOpen: false})
        this.props.loadRecord(this.state.recid)
    }
    undoRecord = () => {
        this.setState({confirmOpen: true})
    }
    notifyError = (errors) => {
        toast.error(errors + "\n(click anywhere to close)", {autoClose: 10000})
    }
    notifySuccess = (msg) => {
        toast.success(msg, {autoClose: 3000})
    }
    errorsOnForm = (model, resetForm, invalidateForm) => {
        toast.error("There are errors on your form! Please review the highlighted fields.", {autoClose: 5000})
    }

    newVendorContactRecord = () => {
        var comp=this
        var parms=[]
        global.getFetch("vendors/contact/new",parms,this.state.access_token).then(function (resp) {
             comp.setState({
                vceditrecord: resp,
                showcontactmodal: true,
                vcrecid: null,
                vceditname: "New Record",
                vcuser_fields: resp.user_fields

            });
        })

    }




    NumberFieldUpdate = (e) => {
        var addldata = this.state.addldata
        addldata[e.target.name] = e.target.value
        this.setState({addldata: addldata})
        this.checkDirty(e.target)

    }

    checkDirty(vals) {
        var savedData = this.state.data
        var comp = this
        Object.keys(vals).map(function (i) {
            if (vals[i] !== savedData[i]) {
                comp.props.dirtyFlag(true)
            }
            return false


        })
    }

    markDirty = (currentValues, isChanged) => {
        this.checkDirty(currentValues)
    }
    vchandleclose = () => {
        this.setState({showcontactmodal: false})
        this.vcupdateTable()

    }

    onDrop = (acceptedFiles) => {
        acceptedFiles.forEach(file => {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('id', this.state.recid)
            formData.append('type', "vendor")
            var comp = this
            global.postFetchx("documents/upload", formData, this.state.access_token).then(function (resp) {
                var tdata = comp.state.data
                tdata.rec.documents = resp.documents
                comp.setState({
                    data: tdata
                })


            });
        })
    }
    ionDrop = (acceptedFiles) => {
        acceptedFiles.forEach(file => {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('id', this.state.recid)
            formData.append('type', "vendor")
            var comp = this
            global.postFetchx("documents/upload_insurance", formData, this.state.access_token).then(function (resp) {
                var tdata = comp.state.data
                tdata.rec.vendor_insurances = resp.documents
                comp.setState({
                    data: tdata,
                    randomkey2: Math.random()
                })


            });
        })
    }
    vcsetDirty = (val) => {
        this.vcisDirty = val
    }
    vcupdateTable = () => {
        var comp = this;
        var parms = {
            vendor_id: this.state.recid

        }

        global.getFetch("vendors/contacts", parms, this.state.access_token).then(function (resp) {
            comp.setState({
                data_vendor_contacts: resp.rec,
                loading: false
            });
        })

    }
    showVendorContact = (rowInfo) => {
        this.isDirty = false
        this.vcloadRecord(rowInfo.row.id)

    }
    ChangeInsurance = (row,type,value) => {
        var parms={type: type, id: row, value: value}
        var comp=this
        global.postFetch("vendors/update_insurance", parms, this.state.access_token).then(function (resp) {
            if (resp.success === "Y") {
                var tdata = comp.state.data
                tdata.rec.vendor_insurances = resp.documents
                comp.setState({
                    data: tdata
                })
            } else {
                comp.notifyError(resp.message)
            }
        })

    }

    renderStatusDropdown = (cellInfo) => {
        var vendor_value = null
        if(cellInfo.index >= this.state.data.rec.vendor_insurances.length) {
            vendor_value = ""
        } else {
            vendor_value = this.state.data.rec.vendor_insurances[cellInfo.index][cellInfo.column.id]
        }
        
        return (
            <select
                style={{ width: "100%" }}
                value={ vendor_value }
                onChange={event => this.ChangeInsurance(cellInfo.row.id,"status",event.target.value)}
        >

                <option value=""></option>
                <option value="confirmed">Confirmed</option>
                <option value="expired">Expired</option>
                <option value="rejected">Rejected</option>
                <option value="waiting">Waiting</option>
            </select>

        );
    }
    renderTypeDropdown = (cellInfo) => {
        var vendor_value = null
        if(cellInfo.index >= this.state.data.rec.vendor_insurances.length) {
            vendor_value = ""
        } else {
            vendor_value = this.state.data.rec.vendor_insurances[cellInfo.index][cellInfo.column.id]
        }
        return (
            <select
                style={{ width: "100%" }}
                value={ vendor_value}
                onChange={event => this.ChangeInsurance(cellInfo.row.id,"insurance_type",event.target.value)}
            >
                <option value=""></option>
                {this.state.data.insurance_types.map((item) => {
                    return <option value={item.key}>{item.value}</option>
                })}


            </select>

        );
    }
    renderCoverage = (cellInfo) => {
        var vendor_value = null
        if(cellInfo.index >= this.state.data.rec.vendor_insurances.length) {
            vendor_value = " "
        } else {
            vendor_value = this.state.data.rec.vendor_insurances[cellInfo.index][cellInfo.column.id]
        }
        return (
            <NumberFormat value={vendor_value}  thousandSeparator={true} prefix={'$'}
                          onBlur={(event) => {
                              this.ChangeInsurance(cellInfo.row.id,"coverage",event.target.value)

                          }}/>

        );
    }
    renderAggregate = (cellInfo) => {
        var vendor_value = null
        if(cellInfo.index >= this.state.data.rec.vendor_insurances.length) {
            vendor_value = " "
        } else {
            vendor_value = this.state.data.rec.vendor_insurances[cellInfo.index][cellInfo.column.id]
        }
        return (
            <NumberFormat value={vendor_value}  thousandSeparator={true} prefix={'$'}
                          onBlur={(event) => {
                              this.ChangeInsurance(cellInfo.row.id,"aggregate",event.target.value)

                          }}/>

        );
    }
    renderUmbrella = (cellInfo) => {
        var vendor_value = null
        if(cellInfo.index >= this.state.data.rec.vendor_insurances.length) {
            vendor_value = " "
        } else {
            vendor_value = this.state.data.rec.vendor_insurances[cellInfo.index][cellInfo.column.id]
        }
        return (
            <NumberFormat value={vendor_value}  thousandSeparator={true} prefix={'$'}
                          onBlur={(event) => {
                              this.ChangeInsurance(cellInfo.row.id,"umbrella",event.target.value)

                          }}/>

        );
    }
    renderExpiration = (cellInfo) => {
        var vendor_value = null
        if(cellInfo.index >= this.state.data.rec.vendor_insurances.length) {
            vendor_value = " "
        } else {
            vendor_value = this.state.data.rec.vendor_insurances[cellInfo.index][cellInfo.column.id] ? global.fixDate(this.state.data.rec.vendor_insurances[cellInfo.index][cellInfo.column.id]) : " "
        }
        return (
            <NumberFormat placeholder="MM/DD/YYYY" format="##/##/####" value={vendor_value}
                          onBlur={(event) => {
                              this.ChangeInsurance(cellInfo.row.id,"expiration",event.target.value)
                          }}/>

        );
    }
    vcloadRecord = (recid) => {
        var parms = {
            id: recid
        }
        var component = this
        global.getFetch("vendors/contacts/read", parms, this.state.access_token).then(function (resp) {
            component.setState({
                vceditrecord: resp,
                showcontactmodal: true,
                vcrecid: recid,
                vceditname: resp.rec.last_name+", "+resp.rec.first_name,
                vcuser_fields: resp.user_fields

            });
        })
    }
    DeleteVendorContact = (recid) => {
        confirmAlert({
            title: 'Confirm Delete',                        // Title dialog
            message: 'Are you sure you want to delete this record?',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {
                var parms = {
                    id: recid
                }
                var component = this
                global.getFetch("vendors/contact/delete", parms, component.state.access_token).then(function (resp) {
                    if (resp.success === "Y") {
                        component.notifySuccess(resp.message)

                        var parms = {
                            vendor_id: component.state.recid

                        }
                        global.getFetch("vendors/contacts", parms, component.state.access_token).then(function (resp) {
                            component.setState({
                                data_vendor_contacts: resp.rec,
                                loading: false
                            });
                        })

                    } else {
                        component.notifyError(resp.message)

                    }

                })
            }
        })

    }
    DeleteDocument = (recid) => {
        confirmAlert({
            title: 'Confirm Delete',                        // Title dialog
            message: 'Are you sure you want to delete this record?',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {
                var parms = {
                    id: recid,
                    field: "vendor_id",
                    fieldvalue: this.state.recid
                }
                var component = this
                global.getFetch("documents/delete", parms, component.state.access_token).then(function (resp) {
                    if (resp.success === "Y") {
                        component.notifySuccess(resp.message)
                        var tdata = component.state.data
                        tdata.rec.documents = resp.documents
                        component.setState({
                            data: tdata
                        })


                    } else {
                        component.notifyError(resp.message)

                    }

                })
            }
        })

    }
    DeleteInsuranceDocument = (recid) => {
        confirmAlert({
            title: 'Confirm Delete',                        // Title dialog
            message: 'Are you sure you want to delete this record?',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {
                var parms = {
                    id: recid,
                    field: "vendor_id",
                    fieldvalue: this.state.recid
                }
                var component = this
                global.getFetch("documents/delete_insurance", parms, component.state.access_token).then(function (resp) {
                    if (resp.success === "Y") {
                        component.notifySuccess(resp.message)
                        var tdata = component.state.data
                        tdata.rec.vendor_insurances = resp.documents
                        component.setState({
                            data: tdata
                        })


                    } else {
                        component.notifyError(resp.message)

                    }

                })
            }
        })

    }
    showDocument = (rowInfo) => {
        var parms = {
           docid: rowInfo.row.id
        }

        global.getFetch("documents/download",parms,this.state.access_token).then(function (resp) {
            global.openLink(resp.file)

        })


    }
    closeModal = () => {
        this.setState({showcontactmodal: false})
        this.vcupdateTable()
    }
    showInsuranceDocument = (rowInfo) => {
        var parms = {
            docid: rowInfo.row.id
        }

        global.getFetch("documents/insurancedownload",parms,this.state.access_token).then(function (resp) {
            global.openLink(resp.file)

        })


    }
    onValidSubmit = (formData, resetForm, invalidateForm) => {
        var data = formData
        var addldata = this.state.addldata
        var comp = this
        var parms = {...data, ...addldata}
        parms.id = this.state.recid
        if (this.state.recid === null) {
            global.postFetch("vendors/create", parms, this.state.access_token).then(function (resp) {
                if (resp.success === "Y") {
                    comp.notifySuccess(resp.message)
                    comp.setState({
                        recid: resp.rec.id,
                        data: resp,
                        user_fields: resp.user_fields
                    })
                    comp.props.dirtyFlag(false)
                } else {
                    comp.notifyError(resp.message)
                    comp.props.dirtyFlag(true)
                }
            })
        } else {
            global.postFetch("vendors/update", parms, this.state.access_token).then(function (resp) {
                if (resp.success === "Y") {
                    comp.notifySuccess(resp.message)
                    comp.setState({
                        recid: resp.rec.id,
                        data: resp,
                        user_fields: resp.user_fields
                    })
                    comp.props.dirtyFlag(false)
                } else {
                    comp.notifyError(resp.message)
                    comp.props.dirtyFlag(true)
                }
            })
        }

    }

    render() {
        const {data_vendor_contacts,access_token, vceditname, vcrecid, vcuser_fields, vceditrecord, randomkey, randomkey2, randomkey3, data, current_user} = this.state
        var panes2 = [
            {
                menuItem: 'Main',
                pane: (
                    <Tab.Pane key='tab1'>


                        <Grid padded={false} columns='equal'>
                            <Grid.Row>
                                <Grid.Column width={3}>
                                    <label><b>Federal ID/SSN</b></label>
                                    <MaskedInput size="10" name="fed_id" onChange={this.NumberFieldUpdate}
                                                 value={this.state.data.rec.fed_id} mask="#########"
                                    />
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <Form.Input
                                        name="number"
                                        size="small"
                                        label="Vendor Number"
                                        placeholder="Vendor Number"
                                        errorLabel={<Label color="red" pointing/>}
                                        defaultValue={this.state.data.rec.number}
                                      />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Input
                                        required
                                        name="name"
                                        label="Vendor Common Name"
                                        placeholder="Vendor Name"
                                        errorLabel={<Label color="red" pointing/>}
                                        defaultValue={this.state.data.rec.name}
                                        value={this.state.data.rec.name}
                                      />

                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Input
                                        required
                                        name="legal_name"
                                        label="Vendor Legal Name"
                                        placeholder="Vendor Legal Name"
                                        errorLabel={<Label color="red" pointing/>}
                                        defaultValue={this.state.data.rec.legal_name}
                                        validationErrors={{
                                            isDefaultRequiredValue: 'Vendor Legal Name is Required',
                                        }}/>

                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>

                                    <Form.Input
                                        required
                                        name="address"
                                        label="Address"
                                        placeholder="Address"
                                        errorLabel={<Label color="red" pointing/>}
                                        defaultValue={this.state.data.rec.address}
                                        validationErrors={{
                                            isDefaultRequiredValue: 'Address is required',
                                        }}/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>

                                    <Form.Input
                                        name="address2"
                                        label="Address 2"
                                        placeholder="Address"
                                        defaultValue={this.state.data.rec.address2}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Input
                                        required

                                        name="city"
                                        label="City"
                                        placeholder="City"
                                        defaultValue={this.state.data.rec.city}
                                    />

                                </Grid.Column>

                                <Grid.Column>

                                    <label><b>State</b></label>

                                    <Dropdown
                                        name="state"
                                        required

                                        label="State"
                                        placeholder="Select State"
                                        search
                                        selection
                                        width={6}
                                        errorLabel={<Label color="red" pointing/>}
                                        defaultValue={this.state.data.rec.state}
                                        options={stateOptions}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Input
                                        name="zip"
                                        required

                                        label="Zip"
                                        placeholder="Zip"
                                        defaultValue={this.state.data.rec.zip}
                                    />

                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>

                                    <label><b>Phone</b></label>
                                    <MaskedInput name="phone" onChange={this.NumberFieldUpdate}
                                                 value={this.state.data.rec.phone} mask="(###)###-####"
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <label><b>Fax</b></label>
                                    <MaskedInput name="cell_phone" onChange={this.NumberFieldUpdate}
                                                 value={this.state.data.rec.fax} mask="(###)###-####"
                                    />

                                </Grid.Column>
                            </Grid.Row>
                        </Grid>


                    </Tab.Pane>
                )
            },
            {
                menuItem: <Menu.Item key="s1"
                                     className={this.state.recid == null ? "hiddenTab" : ""}>Contacts</Menu.Item>,
                pane: (
                    <Tab.Pane key='tab2'>

                        <Menu>
                            <Menu.Item>
                                <Button className={current_user.can_create_vendor_contacts ? "" : "hiddenTab"}
                                        size="tiny" icon='write' label="New Contact"
                                        onClick={this.newVendorContactRecord}/>
                            </Menu.Item>

                        </Menu>

                        <ReactTable
                            key={randomkey3}

                            getTdProps={(state, rowInfo, column, instance) => {
                                return {
                                    onDoubleClick: (e, handleOriginal) => {
                                        // IMPORTANT! React-Table uses onClick internally to trigger
                                        // events like expanding SubComponents and pivots.
                                        // By default a custom 'onClick' handler will override this functionality.
                                        // If you want to fire the original onClick handler, call the
                                        // 'handleOriginal' function.

                                        if (column.Header==="D") {
                                            this.DeleteVendorContact(rowInfo.row.id)
                                        }
                                        if (column.Header==="E") {
                                            this.showVendorContact(rowInfo)

                                        }
                                    }
                                }
                            }}

                            columns={[
                                {
                                    Header: "E",
                                    Cell: row => (
                                        <div class='iconedit'>
                                            <Icon name='edit'/>
                                            <span class="tooltiptext">Double Click to Edit</span>
                                        </div>),
                                    width: 30
                                },


                                current_user.can_delete_vendor_contacts ?

                                    {
                                        Header: "D",
                                        Cell: row => (
                                            <div class='iconedit'>
                                                <Icon name='delete'/>
                                                <span class="tooltiptext">Double Click to Delete</span>
                                            </div>),
                                        width: 30
                                    } :
                                    {
                                        Header: "",
                                        width: 1
                                    }
                                ,

                                {
                                    Header: "Id",
                                    accessor: "id"
                                },
                                {
                                    Header: "Last Name",
                                    accessor: "last_name"
                                },
                                {
                                    Header: "First Name",
                                    accessor: "first_name"
                                },
                                {
                                    Header: "Title",
                                    accessor: "title"
                                },
                                {
                                    Header: "Email",
                                    accessor: "email"
                                },
                                {
                                    Header: "Work Phone",
                                    accessor: "work_phone"
                                }
                            ]}
                            showPaginationTop
                            data={data_vendor_contacts}
                            className="-striped -highlight"
                        />

                    </Tab.Pane>
                )
            },
            {
                menuItem: <Menu.Item key="s2" className={this.state.recid == null ? "hiddenTab" : ""}>Users</Menu.Item>,
                pane: (
                    <Tab.Pane key='tab3'>
                        <label><b>Users</b></label>

                        <Dropdown
                            fluid
                            selection
                            search={true}
                            multiple={true}
                            options={this.state.data.common.users}
                            value={this.state.data.rec.user_vends}
                            placeholder='Add Users'
                            name="user_ids"
                        />
                    </Tab.Pane>
                )
            },
            {
                menuItem: <Menu.Item key="s4a"
                                     className={this.state.recid == null || !current_user.can_manage_vendor_insurance ? "hiddenTab" : ""}>Insurance</Menu.Item>,
                pane: (
                    <Tab.Pane key='tab5'>
                        { current_user.can_upload_vendor_documents ?

                            <DropZone className="dropzone" ref="idropzone" multiple={true} onDrop={this.ionDrop}>
                                Drag file(s) here or click to browse.
                            </DropZone> : "" }<br/>
                        <ReactTable
                            key={randomkey2}

                            getTdProps={(state, rowInfo, column, instance) => {
                                return {
                                    onDoubleClick: (e, handleOriginal) => {
                                        // IMPORTANT! React-Table uses onClick internally to trigger
                                        // events like expanding SubComponents and pivots.
                                        // By default a custom 'onClick' handler will override this functionality.
                                        // If you want to fire the original onClick handler, call the
                                        // 'handleOriginal' function.
                                        if (column.Header==="P") {
                                            this.showInsuranceDocument(rowInfo)
                                        }
                                        if (column.Header==="D") {
                                            this.DeleteInsuranceDocument(rowInfo.row.id)
                                        }

                                    }
                                }
                            }}
                            columns={[

                                {
                                    Header: "P",
                                    Cell: row => (
                                        <div>
                                            <Icon name='file pdf outline'/>
                                        </div>),
                                    width: 30
                                },
                                current_user.can_delete_vendor_documents ?

                                    {
                                        Header: "D",
                                        Cell: row => (
                                            <div class='iconedit'>
                                                <Icon name='delete'/>
                                                <span class="tooltiptext">Double Click to Delete</span>
                                            </div>),
                                        width: 30
                                    } :
                                    {
                                        Header: "",
                                        width: 1
                                    }
                                ,


                                {
                                    Header: "Id",
                                    accessor: "id",
                                    width: 0,
                                    style: {display: "none"}
                                },
                                {
                                    Header: "Type",
                                    accessor: "insurance_type",
                                    width: 250,
                                    Cell: this.renderTypeDropdown
                                },
                                {
                                    Header: "Status",
                                    accessor: "status",
                                    width: 200,
                                    Cell: this.renderStatusDropdown

                                },
                                {
                                    Header: "Per Occurrence",
                                    accessor: "coverage_per",
                                    Cell: this.renderCoverage
                                },
                                 {
                                    Header: "Aggregate",
                                    accessor: "aggregate",
                                    Cell: this.renderAggregate
                                },
                                {
                                    Header: "Umbrella",
                                    accessor: "umbrella",
                                    Cell: this.renderUmbrella

                                },
                                {
                                    Header: "Expiration",
                                    accessor: "expiration",
                                    Cell: this.renderExpiration
                                }
                            ]}
                            showPaginationTop
                            data={data.rec.vendor_insurances}
                            className="-striped -highlight"
                        />


                    </Tab.Pane>
                )
            },
            {
                menuItem: <Menu.Item key="s4"
                                     className={this.state.recid == null || !current_user.can_view_vendor_documents ? "hiddenTab" : ""}>Documents</Menu.Item>,
                pane: (
                    <Tab.Pane key='tab5a'>
                        { current_user.can_upload_vendor_documents ?

                        <DropZone className="dropzone" ref="dropzone" multiple={true} onDrop={this.onDrop}>
                            Drag file(s) here or click to browse.
                        </DropZone> : "" }<br/>
                        <ReactTable
                            key={randomkey}
                            getTrProps={(state, rowInfo, column, instance) => {
                                return {
                                    onDoubleClick: (e, handleOriginal) => {
                                        // IMPORTANT! React-Table uses onClick internally to trigger
                                        // events like expanding SubComponents and pivots.
                                        // By default a custom 'onClick' handler will override this functionality.
                                        // If you want to fire the original onClick handler, call the
                                        // 'handleOriginal' function.
                                        this.showDocument(rowInfo)


                                    }
                                }
                            }}
                            getTdProps={(state, rowInfo, column, instance) => {
                                return {
                                    onDoubleClick: (e, handleOriginal) => {
                                        // IMPORTANT! React-Table uses onClick internally to trigger
                                        // events like expanding SubComponents and pivots.
                                        // By default a custom 'onClick' handler will override this functionality.
                                        // If you want to fire the original onClick handler, call the
                                        // 'handleOriginal' function.
                                        if (column.Header==="P") {
                                            this.showDocument(rowInfo)
                                        }
                                        if (column.Header==="D") {
                                            this.DeleteDocument(rowInfo.row.id)
                                        }

                                    }
                                }
                            }}
                            columns={[

                                {
                                    Header: "P",
                                    Cell: row => (
                                        <div>
                                            <Icon name='file pdf outline'/>
                                        </div>),
                                    width: 30
                                },
                                current_user.can_delete_vendor_documents ?

                                    {
                                        Header: "D",
                                        Cell: row => (
                                            <div class='iconedit'>
                                                <Icon name='delete'/>
                                                <span class="tooltiptext">Double Click to Delete</span>
                                            </div>),
                                        width: 30
                                    } :
                                    {
                                        Header: "",
                                        width: 1
                                    }
                                ,


                                {
                                    Header: "Id",
                                    accessor: "id",
                                    width: 60
                                },
                                {
                                    Header: "File Name",
                                    accessor: "filename"
                                },
                                {
                                    Header: "Date Created",
                                    accessor: "created_at",
                                    Cell: row => (
                                        <div>
                                            {row.value === null ? " " :
                                                <Moment format="MM/DD/YYYY">
                                                    {row.value}
                                                </Moment>
                                            }
                                        </div>
                                    )
                                }
                            ]}
                            showPaginationTop
                            data={data.rec.documents}
                            className="-striped -highlight"
                        />


                    </Tab.Pane>
                )
            }

        ]
        const panes=Object.assign(panes2,UserFields(this.state.user_fields,5,this.state.data.rec))

        return (
            <div>
                <Confirm
                    open={this.state.confirmOpen}
                    onCancel={this.handleCancel}
                    onConfirm={this.handleUndoConfirm}
                    content="Are you sure you want to undo changes?"
                    style={{fontSize: "1.2rem"}}

                />
                <Menu>
                    <Menu.Item>
                        {current_user.can_update_vendors ?

                            <Button size="tiny" color="green" onClick={this.handleSave}>
                                <Icon name='save'/> Save
                            </Button> : ""}
                    </Menu.Item>

                </Menu>
                <br/>



                <Form onChange={this.markDirty} ref="editForm" autoComplete="off"
                      onSubmit={current_user.can_update_vendors ? this.onValidSubmit : ""}>

                    <Tab key="tabtab" renderActiveOnly={false} panes={panes}>
                    </Tab>



                </Form>
                <Modal
                    open={this.state.showcontactmodal}
                    size='large' closeIcon
                    closeOnRootNodeClick={true}
                    onClose={this.vchandleclose}


                >

                    <Header icon='browser' content={vceditname}/>
                    <Modal.Content>
                        <Confirm
                            open={this.state.confirmOpen}
                            onCancel={this.handleCancel}
                            onConfirm={this.handleConfirm}
                            content="You have unsaved changes. Are you sure?"
                            style={{fontSize: "1.2rem"}}

                        />

                        <VendorContactEdit vendor_id={this.state.recid} dirtyFlag={this.vcsetDirty} data={vceditrecord} user_fields={vcuser_fields} recid={vcrecid} access_token={access_token} closeModal={this.closeModal} loadRecord={this.vcloadRecord} updateTable={this.vcupdateTable} />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button size="tiny" color='green' onClick={this.vchandleclose} inverted>
                            <Icon name='checkmark'/> Close
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>

        );
    }
}


