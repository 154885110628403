import React, {Component} from 'react';
import ReactTable from "react-table";
import global from './global'
import {toast} from 'react-toastify';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Menu from 'semantic-ui-react/dist/es/collections/Menu';
import Button from 'semantic-ui-react/dist/es/elements/Button';
import Icon from 'semantic-ui-react/dist/es/elements/Icon';
import {Form} from 'formsy-semantic-ui-react';
import Modal from 'semantic-ui-react/dist/es/modules/Modal';
import Header from 'semantic-ui-react/dist/es/elements/Header';
import Label from 'semantic-ui-react/dist/es/elements/Label';


export class Emails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: this.props.access_token,
            recid: this.props.recid,
            data: this.props.data,
            departments: this.props.departments,
            dirtyFlag: this.props.dirtyFlag,
            confirmOpen: false,
            current_user: JSON.parse(sessionStorage.getItem("current_user")),
            showpreview: false,
            editRecord: {from_email: " ", subject: " ", template: " "}


        };

    }

    newRecord = () => {
        this.setState({
            editRecord: [],
            showmodal: true,
            recid: null,
            editname: "New Record"

        });

    }
    updateTable = () => {
        var comp = this;
        var parms = {
            pageSize: this.state.pageSize,
            page: this.state.currentpage,
            sorted: this.state.sorted,
            search: this.state.searchkey
        }

        global.getFetch("emails", parms, this.state.access_token).then(function (resp) {
            comp.setState({
                data: resp.rows,
                pages: resp.pages,
                loading: false,
            });
        })

    }
    handleclose = () => {
        this.setState({showmodal: false})
        this.updateTable()

    }
    fetchData = (state, instance) => {
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        this.setState({loading: true});
        var srts = ""
        state.sorted.map(function (i) {
            srts += (srts === "" ? "" : ",") + i.id + (i.desc ? " desc" : " asc")
            return srts

        })
        if (srts === "") {
            srts = "key_value asc"
        }
        var component = this;
        var parms = {
            pageSize: state.pageSize,
            page: state.page + 1,
            sorted: srts,
            search: this.state.searchkey
        }

        global.getFetch("emails", parms, this.state.access_token).then(function (resp) {
            component.setState({
                data: resp.rows,
                pages: resp.pages,
                loading: false,
                currentpage: state.page + 1,
                parms: parms,
                sorted: srts,
                pageSize: state.pageSize
            });
        })
    }
    handleSave = () => {
         this.refs.editForm.submit()

    }
    loadRecord = (recid) => {
        var parms = {
            id: recid
        }
        var component = this
        global.getFetch("emails/read", parms, this.state.access_token).then(function (resp) {
            component.setState({
                editRecord: resp.rec,
                showmodal: true,
                recid: recid,
                editname: resp.rec.key_value

            });
        })
    }
    refreshtable = () => {
        this.setState({randomkey: Math.random(), searchkey: ""})
    }
    notifyError = (errors) => {
        toast.error(errors + "\n(click anywhere to close)", {autoClose: 10000})
    }
    notifySuccess = (msg) => {
        toast.success(msg, {autoClose: 3000})
    }
    onValidSearchSubmit = (formData) => {
        this.setState({searchkey: formData.search, loading: true, randomkey: Math.random()})

        var component = this;
        var parms = {
            pageSize: this.state.pageSize,
            page: 1,
            sorted: this.state.sorted,
            search: formData.search
        }

        global.getFetch("emails", parms, this.state.access_token).then(function (resp) {
            component.setState({
                data: resp.rows,
                pages: resp.pages,
                loading: false,
                parms: parms
            });
        })
    }
    DeleteEntry = (recid) => {
        confirmAlert({
            title: 'Confirm Delete',                        // Title dialog
            message: 'Are you sure you want to delete this record?',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {
                var parms = {
                    id: recid
                }
                var component = this
                global.getFetch("emails/delete", parms, component.state.access_token).then(function (resp) {
                    if (resp.success === "Y") {
                        component.notifySuccess(resp.message)
                        var comp = component;
                        var parms = {
                            pageSize: comp.state.pageSize,
                            page: comp.state.currentpage,
                            sorted: comp.state.sorted,
                            search: comp.state.searchkey
                        }

                        global.getFetch("emails", parms, component.state.access_token).then(function (resp) {
                            comp.setState({
                                data: resp.rows,
                                pages: resp.pages,
                                loading: false,
                            });
                        })

                    } else {
                        comp.notifyError(resp.message)

                    }

                })
            }
        })

    }
    onValidSubmit = (formData, resetForm, invalidateForm) => {

        var comp = this
        var parms = formData
        global.postFetch("emails/update", parms, this.state.access_token).then(function (resp) {
            if (resp.success === "Y") {
                comp.notifySuccess(resp.message)
                var parms = {
                    pageSize: comp.state.pageSize,
                    page: comp.state.currentpage,
                    sorted: comp.state.sorted,
                    search: comp.state.searchkey
                }

                global.getFetch("emails", parms, comp.state.access_token).then(function (resp) {
                    comp.setState({
                        data: resp.rows,
                        pages: resp.pages,
                        loading: false,
                    });
                })
                comp.setState({showmodal: false})



            } else {
                comp.notifyError(resp.message)

            }
        })

    }

    render() {
        const {data,  pages, pageSize, loading, randomkey, editname} = this.state
         return (
            <div>
                <Modal
                    open={this.state.showmodal}
                    size='large' closeIcon
                    closeOnRootNodeClick={true}
                    onClose={this.handleclose}


                >

                    <Header icon='browser' content={editname}/>

                    <Modal.Content>
                        <Menu>
                            <Menu.Item>
                                <Button size="tiny" color="green" onClick={this.handleSave}>
                                    <Icon name='save'/> Save
                                </Button>
                            </Menu.Item>

                        </Menu> <br/>
                        <Form ref="editForm" onSubmit={this.onValidSubmit}>
                            <Form.Input
                                defaultValue={this.state.editRecord.id}
                                name="id"
                                style={{display: "none"}}

                            />
                            <Form.Input
                                required
                                name="from_email"
                                label="From Email"
                                placeholder="From Email"
                                errorLabel={<Label color="red" pointing/>}
                                defaultValue={this.state.editRecord.from_email}
                                validationErrors={{
                                    isDefaultRequiredValue: 'From Email is Required',
                                }}
                            />
                            <Form.Input
                                required
                                name="subject"
                                label="Subject"
                                placeholder="Subject"
                                errorLabel={<Label color="red" pointing/>}
                                defaultValue={this.state.editRecord.subject}
                                validationErrors={{
                                    isDefaultRequiredValue: 'Subject is Required',
                                }}
                            />
                            <Form.TextArea
                                autoHeight={true}
                                name="template"
                                label="Body"
                                placeholder="Body"
                                defaultValue={this.state.editRecord.template}


                            />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button size="tiny" color='green' onClick={this.handleclose} inverted>
                            <Icon name='checkmark'/> Close
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Menu style={{border: 'none'}}>
                    <Menu.Item style={{border: 'none'}}>
                       <Button size="tiny" icon='refresh' label="Refresh Page" onClick={this.refreshtable}/>

                    </Menu.Item>
                    <Menu.Menu position='right' style={{border: 'none'}}>
                        <Menu.Item style={{border: 'none'}}>
                            <Button size="tiny" icon="file excel outline" label="Export" onClick={this.exportdata}/>
                        </Menu.Item>
                        <Menu.Item style={{border: 'none'}}>
                            <Form ref="searchform" onValidSubmit={this.onValidSearchSubmit}>
                                <Form.Input name="search" action={{icon: 'search'}} placeholder='Search...'
                                           />
                            </Form>
                        </Menu.Item>
                    </Menu.Menu>

                </Menu>
                <ReactTable
                    key={randomkey}
                    data={data}
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onDoubleClick: (e, handleOriginal) => {
                                // IMPORTANT! React-Table uses onClick internally to trigger
                                // events like expanding SubComponents and pivots.
                                // By default a custom 'onClick' handler will override this functionality.
                                // If you want to fire the original onClick handler, call the
                                // 'handleOriginal' function.
                               if (column.Header==="E" ) {
                                   this.loadRecord(rowInfo.row.id)
                                }
                            }
                        }
                    }}
                    columns={[

                        {
                            Header: "E",
                            accessor: "id",
                            Cell: row => (
                                <div class='iconedit'>
                                    <Icon name='edit'/>
                                    <span class="tooltiptext">Double Click to Edit</span>
                                </div>),
                            width: 30
                        },
                        {
                            Header: "Key",
                            accessor: "key_value"
                        },

                        {
                            Header: "From Email",
                            accessor: "from_email"
                        }
                        ,
                        {
                            Header: "Subject",
                            accessor: "subject"
                        }
                    ]}
                    showPaginationTop
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    pages={pages} // Display the total number of pages
                    loading={loading} // Display the loading overlay when we need it
                    onFetchData={this.fetchData} // Request new data when things change
                    defaultPageSize={pageSize}
                    className="-striped -highlight"
                />
            </div>

        );
    }
}


