/* eslint no-lone-blocks: 0 */
/* eslint eqeqeq: 0 */
/* eslint no-unused-vars: 0 */
import React, {Component} from 'react';
import './App.css';
import ReactTable from "react-table";
import "react-table/react-table.css";
import {ContractEditRecord} from "./ContractEdit";
import {ContractEditRecordOntario} from "./ContractEditOntario";
import {ContractEditRecordDemo} from "./ContractEditDemo";
import {ContractEditRecordOtsego} from "./ContractEditOtsego";
import {ContractEditRecordRensco} from "./ContractEditRensco";
import {ContractEditRecordOswego} from "./ContractEditOswego";
import {ContractEditRecordAlbany} from "./ContractEditAlbany";
import {ContractEditRecordLyn} from "./ContractEditLyn";
import { ExportEdit } from "./ExportEdit";
import Moment from "react-moment"
import Menu from 'semantic-ui-react/dist/es/collections/Menu';
import Button from 'semantic-ui-react/dist/es/elements/Button';
import Icon from 'semantic-ui-react/dist/es/elements/Icon';
import global from "./global"
import {Form} from 'formsy-semantic-ui-react';
import Header from 'semantic-ui-react/dist/es/elements/Header';
import Modal from 'semantic-ui-react/dist/es/modules/Modal';
import Confirm from 'semantic-ui-react/dist/es/addons/Confirm';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import {toast} from 'react-toastify';
import Loader from 'semantic-ui-react/dist/es/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/es/modules/Dimmer';
import QueryBuilder from 'react-querybuilder';
import {formatQuery} from "./utils"
import {Dropdown} from 'semantic-ui-react'

import Accordion from 'semantic-ui-react/dist/es/modules/Accordion'

export class Contracts extends Component {
    componentWillMount = () => {
        this.setState({
            access_token: this.props.access_token,
            isLoggedIn: this.props.isloggedIn,
            status: this.props.cstatus,
            data: [],
            pages: null,
            loading: true,
            currentpage: 1,
            randomkey: Math.random(),
            querykey: Math.random(),
            confirmOpen: false,
            showmodal: false,
            showExportModal: false,
            searchkey: "",
            pageSize: 20,
            current_user: JSON.parse(sessionStorage.getItem("current_user")),
            showdimmer: false,
            activeIndex: 0,
            rules: null,
            downloading: false,
            sorted: "contract_dashboard.start_date desc,contract_dashboard.contract_id desc"


        });

    }

    onValidSubmit = (formData) => {
        this.setState({searchkey: formData.search, loading: true, randomkey: Math.random()})
        var obj = JSON.parse(sessionStorage.getItem("current_user"));
        var component = this;
        var parms = {
            pageSize: this.state.pageSize,
            page: 1,
            sorted: this.state.sorted,
            search: formData.search,
            uid: obj.id,
            rules: this.state.rules
        }

        global.getFetch(this.props.path, parms, this.state.access_token).then(function (resp) {
            component.setState({
                data: resp.rows,
                pages: resp.pages,
                loading: false,
                parms: parms,

            });
        })
    }

    handleItemClick = (e, {name}) => {
        this.setState({activeItem: name})
    }

    refreshtable = () => {
        this.refs.searchform.reset()
        this.setState({randomkey: Math.random(), searchkey: ""})
    }
    onExportOpen = () => {
        this.setState({showExportModal: true})
    }

    handleclose = () => {
        this.updateTable()
        this.setState({confirmOpen: true})

    }
    handleExportClose = () => {
        this.setState({showExportModal: false})
    }
    doAdvanced = () => {
        this.setState({randomkey: Math.random()})
    }
    resetAdvanced = () => {
        this.setState({randomkey: Math.random(), rules: null, querykey: Math.random()})
    }

    showContract = (rowInfo) => {
        this.isDirty = false
        this.loadRecord(rowInfo.row.id)
    }
    loadDefault = () => {
        var comp = this
        global.getFetch("common/af_fields", {}, this.props.access_token).then(function (resp) {
            comp.setState({
                af_fields: resp.af_fields

            });
        })

    }
    newRecord = () => {
        var comp = this
        var parms = []
        global.getFetch("contracts/new", parms, this.state.access_token).then(function (resp) {
            comp.setState({
                editRecord: resp,
                showmodal: true,
                recid: null,
                editname: "New Contract",
                user_fields: resp.user_fields,
                columns: resp.columns,

            });
        })

    }
    updateTable = () => {
        var comp = this;
        var obj = JSON.parse(sessionStorage.getItem("current_user"));

        var parms = {
            pageSize: this.state.pageSize,
            page: this.state.currentpage,
            sorted: this.state.sorted,
            search: this.state.searchkey,
            uid: obj.id,
            status: this.state.status

        }

        global.getFetch(this.props.path, parms, this.state.access_token).then(function (resp) {
            comp.setState({
                data: resp.rows,
                pages: resp.pages,
                loading: false,
            });
        })

    }
    handleAccordionClick = (e, titleProps) => {
        const {index} = titleProps
        const {activeIndex} = this.state
        const newIndex = activeIndex === index ? -1 : index
        this.setState({activeIndex: newIndex})
    }
    exportdata = () => {

        var obj = JSON.parse(sessionStorage.getItem("current_user"));
        var comp = this;

        if(this.state.downloading) {
            this.notifyError("A export request is already in progress")
        } else {
            var parms = {
                pageSize: 99999999,
                page: this.state.currentpage,
                sorted: this.state.sorted,
                search: this.state.searchkey,
                uid: obj.id,
                rules: this.state.rules,
                status: this.state.status

            }
            this.setState({downloading: true})


            this.notifySuccess("Generating your document, please do not navigate away from this page")
            global.getFetch(this.props.path + "_excel", parms, this.state.access_token).then(function (resp) {
                global.openLink(resp.xls)
                comp.setState({downloading: false})

            })
        }
    }
    fetchData = (state, instance) => {
        this.loadDefault()
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        var obj = JSON.parse(sessionStorage.getItem("current_user"));
        this.setState({loading: true});
        var srts = ""
        state.sorted.map(function (i) {
            srts += (srts === "" ? "" : ",") + (!i.id.includes(".") ? "contracts." : "") + i.id + (i.desc ? " desc" : " asc")
            return srts
        })
        if (srts === "") {
            srts = "contract_dashboard.start_date desc,contract_dashboard.contract_id desc"
        }
        var component = this;
        var parms = {
            pageSize: state.pageSize,
            page: state.page + 1,
            sorted: srts,
            search: this.state.searchkey,
            uid: obj.id,
            rules: this.state.rules,
            status: this.state.status

        }

        global.getFetch(this.props.path, parms, this.state.access_token).then(function (resp) {
            component.setState({
                data: resp.rows,
                pages: resp.pages,
                loading: false,
                currentpage: state.page + 1,
                parms: parms,
                sorted: srts,
                pageSize: state.pageSize,
                itypes: resp.itypes
            });
        })
    }
    handleConfirm = () => {
        this.updateTable()
        this.setState({confirmOpen: false, showmodal: false})

    }
    DeleteContract = (recid) => {
        confirmAlert({
            title: 'Confirm Delete',                        // Title dialog
            message: 'Are you sure you want to delete this contract?',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {
                var parms = {
                    id: recid
                }
                global.getFetch("contracts/delete", parms, this.state.access_token).then(resp => {
                    if (resp.success === "Y") {
                        this.notifySuccess(resp.message)
                        this.updateTable()


                    } else {
                        this.notifyError(resp.message)

                    }

                })
            }
        })

    }
    handleCancel = () => {
        this.setState({confirmOpen: false})

    }
    handleSearchChange = (query) => {

    }
    notifyError = (errors) => {
        toast.error(errors + "\n(click anywhere to close)", {autoClose: 10000})
    }
    notifySuccess = (msg) => {
        toast.success(msg, {autoClose: 3000})
    }
    loadRecord = (recid) => {
        var parms = {
            id: recid
        }
        var component = this
        this.setState({showdimmer: true})
        global.getFetch("contracts/read", parms, this.state.access_token).then(function (resp) {
            component.setState({
                editRecord: resp,
                showmodal: true,
                recid: recid,
                editname: resp.rec.name,
                user_fields: resp.user_fields,
                columns: resp.columns,
                showdimmer: false

            });
        })
    }
    handleQueryChange = (q) => {
        var obj = JSON.parse(sessionStorage.getItem("current_user"));
        this.setState({rules: formatQuery(q, "sql")})

    }
    getExportModal = () => {
        const {activeIndex, itypes, columns, access_token, user_fields, data, pages, loading, randomkey, recid, editname, editRecord, current_user} = this.state
        var obj = JSON.parse(sessionStorage.getItem("current_user"));
        var parms = {
            pageSize: 99999999,
            page: this.state.currentpage,
            sorted: this.state.sorted,
            search: this.state.searchkey,
            uid: obj.id,
            rules: this.state.rules,
            status: this.state.status

        }
        return (<ExportEdit dirtyFlag={this.setDirty} data={{quarter: 1}}
            finaldata={this.checkClose}
            user_fields={user_fields} recid={recid}
            access_token={access_token}
            loadRecord={this.loadRecord} updateTable={this.updateTable}
            columns={columns} parms={parms} path={this.props.path} itypes={itypes}/>)
    }
    getCoverSheet = () => {
        const {activeIndex, itypes, columns, access_token, user_fields, data, pages, loading, randomkey, recid, editname, editRecord, current_user} = this.state

        {
            switch (sessionStorage.getItem("client_cover")) {
                case 'ontario':
                    return (<ContractEditRecordOntario dirtyFlag={this.setDirty} data={editRecord}
                                                       finaldata={this.checkClose}
                                                       user_fields={user_fields} recid={recid}
                                                       access_token={access_token}
                                                       loadRecord={this.loadRecord} updateTable={this.updateTable}
                                                       columns={columns} itypes={itypes}/>)
                case 'ontariodev':
                    return (<ContractEditRecordOntario dirtyFlag={this.setDirty} data={editRecord}
                                                       finaldata={this.checkClose}
                                                       user_fields={user_fields} recid={recid}
                                                       access_token={access_token}
                                                       loadRecord={this.loadRecord} updateTable={this.updateTable}
                                                       columns={columns} itypes={itypes}/>)
                case 'oswego':
                    return (<ContractEditRecordOswego dirtyFlag={this.setDirty} data={editRecord}
                                                       finaldata={this.checkClose}
                                                       user_fields={user_fields} recid={recid}
                                                       access_token={access_token}
                                                       loadRecord={this.loadRecord} updateTable={this.updateTable}
                                                       columns={columns} itypes={itypes}/>)

                case 'demo':
                    return (<ContractEditRecordDemo dirtyFlag={this.setDirty} data={editRecord}
                                                       finaldata={this.checkClose}
                                                       user_fields={user_fields} recid={recid}
                                                       access_token={access_token}
                                                       loadRecord={this.loadRecord} updateTable={this.updateTable}
                                                       columns={columns} itypes={itypes}/>)

                case 'otsego':
                    return (<ContractEditRecordOtsego dirtyFlag={this.setDirty} data={editRecord}
                                                       finaldata={this.checkClose}
                                                       user_fields={user_fields} recid={recid}
                                                       access_token={access_token}
                                                       loadRecord={this.loadRecord} updateTable={this.updateTable}
                                                       columns={columns} itypes={itypes}/>)
                case 'rensco':
                    return (<ContractEditRecordRensco dirtyFlag={this.setDirty} data={editRecord}
                                                       finaldata={this.checkClose}
                                                       user_fields={user_fields} recid={recid}
                                                       access_token={access_token}
                                                       loadRecord={this.loadRecord} updateTable={this.updateTable}
                                                       columns={columns} itypes={itypes}/>)
                case 'lyn':
                    return (<ContractEditRecordLyn dirtyFlag={this.setDirty} data={editRecord}
                                                       finaldata={this.checkClose}
                                                       user_fields={user_fields} recid={recid}
                                                       access_token={access_token}
                                                       loadRecord={this.loadRecord} updateTable={this.updateTable}
                                                       columns={columns} itypes={itypes}/>)
                case 'albany':
                    return (<ContractEditRecordAlbany dirtyFlag={this.setDirty} data={editRecord}
                                                       finaldata={this.checkClose}
                                                       user_fields={user_fields} recid={recid}
                                                       access_token={access_token}
                                                       loadRecord={this.loadRecord} updateTable={this.updateTable}
                                                       columns={columns} itypes={itypes}/>)

                default:
                    return (<ContractEditRecord dirtyFlag={this.setDirty} data={editRecord} finaldata={this.checkClose}
                                                user_fields={user_fields} recid={recid} access_token={access_token}
                                                loadRecord={this.loadRecord} updateTable={this.updateTable}
                                                columns={columns} itypes={itypes}/>)
            }
        }

    }

    render() {
        const {activeIndex, itypes, columns, access_token, user_fields, data, pages, loading, randomkey, recid, editname, editRecord, current_user} = this.state
        const fields = [
            {id: "contracts.contract_ceiling", name: 'contracts.contract_ceiling', label: 'Contract Ceiling'},

            {id: "contracts.name", name: 'contracts.name', label: 'Contract Name'},
            {
                id: "contracts.start_date.format_date",
                name: 'contracts.start_date.format_date',
                label: 'Contract Start Date'
            },
            {id: "contracts.end_date.format_date", name: 'contracts.end_date.format_date', label: 'Contract End Date'},
            {id: "contracts.status", name: 'contracts.status', label: 'Contract Status'},
            {id: "departments.name", name: "departments.name", label: "Department"},
            {id: "contract_dashboard.organization_name", name: "contract_dashboard.organization_name", label: "Organization"},
            {id: "contract_dashboard.last_action", name: "contract_dashboard.last_action", label: "Waiting On"}

        ]
         const operators = [
            {name: 'is null', label: 'Is Null'},
            {name: 'is not null', label: 'Is Not Null'},
            {name: 'in', label: 'In'},
            {name: 'not in', label: 'Not In'},
            {name: '=', label: 'equals'},
            {name: '<>', label: 'does not equal'},
            {name: '<', label: 'is less than'},
            {name: '>', label: 'is greater than'},
            {name: '<=', label: 'less than or equal'},
            {name: '>=', label: 'greater than or equal'},
            {name: 'like_start', label: 'starts with'},
            {name: 'like_end', label: 'ends with'},
            {name: 'like', label: 'contains'},
        ]
        const translations = {
            fields: {
                title: "Fields",
            },
            operators: {
                title: "Operators",
            },
            value: {
                title: "Value",
            },
            removeRule: {
                label: "Delete",
                title: "Remove rule",
            },
            removeGroup: {
                label: "Delete",
                title: "Remove group",
            },
            addRule: {
                label: "Add Condition",
                title: "Add rule",
            },
            addGroup: {
                label: "Add Grouping",
                title: "Add group",
            },
            combinators: {
                title: "Combinators",
            }
        }
        return (

            <div>
                <Dimmer active={this.state.showdimmer}>
                    <Loader size='small' active={this.state.showdimmer}>Working.....</Loader></Dimmer>
                <Menu style={{paddingBottom: "0px", margin: "0px"}}>

                    <Menu.Item>
                        {current_user.can_create_new_contracts ?
                            <Button size="tiny" icon='write' label="New Contract" onClick={this.newRecord}/> : ""}
                        <Button size="tiny" icon='refresh' label="Refresh Page" onClick={this.refreshtable}/>
                    </Menu.Item>

                    <Menu.Menu position='right'>
                        <Menu.Item style={{border: 'none'}}>
                        <Button size="tiny" icon="file excel outline" label="Export" onClick={this.exportdata}/>
                            {sessionStorage.getItem("client_cover") == 'albany' || sessionStorage.getItem("client_cover") == 'lyn' ?
                            <Button size="tiny" icon="file excel outline" label="Export Activities" onClick={this.onExportOpen}/> : ""}
                             
                        </Menu.Item>
                        <Menu.Item>
                            <Form ref="searchform" onValidSubmit={this.onValidSubmit}>
                                <Form.Input name="search" action={{icon: 'search'}} placeholder='Search...'
                                            onChange={this.handleChange}/>
                            </Form>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
                <Accordion fluid styled>
                    <Accordion.Title active={activeIndex === 33} index={33}
                                     onClick={this.handleAccordionClick}>
                        <Icon name='dropdown'/>
                        Advanced Filter
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 33}>
                        <Menu style={{paddingBottom: "0px", margin: "0px"}}>

                            <Menu.Menu position='right'>
                                <Menu.Item>
                                    <Button size="tiny" icon='search' label="Run Query"
                                            onClick={this.doAdvanced}/> &nbsp;&nbsp;
                                    <Button size="tiny" icon='refresh' label="Clear Query"
                                            onClick={this.resetAdvanced}/>
                                </Menu.Item>
                            </Menu.Menu>
                        </Menu>
                        <QueryBuilder key={this.state.querykey} translations={translations} operators={operators}
                                      fields={fields}
                                      onQueryChange={this.handleQueryChange}/>
                    </Accordion.Content></Accordion>

                <Modal
                    open={this.state.showmodal}
                    size='large' closeIcon
                    onClose={this.handleclose}
                    closeOnDimmerClick={false}



                >

                    <Header icon='browser' content={editname}/>
                    <Modal.Content>
                        <Confirm
                            open={this.state.confirmOpen}
                            onCancel={this.handleCancel}
                            onConfirm={this.handleConfirm}
                            content="Any unsaved changes will be lost! Are you sure?"
                            style={{fontSize: "1.2rem"}}

                        />
                        {this.getCoverSheet()}

                    </Modal.Content>
                    <Modal.Actions>
                        <Button size="tiny" color='green' onClick={this.handleclose} inverted>
                            <Icon name='checkmark'/> Close
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal
                    open={this.state.showExportModal}
                    size='large' closeIcon
                    onClose={this.handleExportClose}
                    closeOnDimmerClick={true}
                >
                    <Header icon='browser' content={'Export'}/>
                    <Modal.Content>
                        <Confirm
                            open={this.state.confirmOpen}
                            onCancel={this.handleCancel}
                            onConfirm={this.handleConfirm}
                            content="Any unsaved changes will be lost! Are you sure?"
                            style={{fontSize: "1.2rem"}}

                        />
                        {this.getExportModal()}

                    </Modal.Content>
                    <Modal.Actions>
                        <Button size="tiny" color='green' onClick={this.handleclose} inverted>
                            <Icon name='checkmark'/> Close
                        </Button>
                    </Modal.Actions>

                    
                </Modal>
                <ReactTable style={{cursor: "pointer"}}
                            key={randomkey}
                            getTdProps={(state, rowInfo, column, instance) => {
                                return {
                                    onDoubleClick: (e, handleOriginal) => {
                                        // IMPORTANT! React-Table uses onClick internally to trigger
                                        // events like expanding SubComponents and pivots.
                                        // By default a custom 'onClick' handler will override this functionality.
                                        // If you want to fire the original onClick handler, call the
                                        // 'handleOriginal' function.
                                        if (column.Header === "D") {
                                            this.DeleteContract(rowInfo.row.id)
                                        }
                                        if (column.Header === "V") {
                                            this.showContract(rowInfo)

                                        }


                                    }
                                }
                            }}
                            columns={[
                                current_user.can_view_contracts ?

                                    {
                                        Header: "V",
                                        Cell: row => (
                                            <div class='iconedit'>
                                                <Icon name='edit'/>
                                                <span class="tooltiptext">Double Click to Edit</span>
                                            </div>),
                                        width: 30
                                    } : {
                                        Header: "",
                                        width: 1
                                    },
                                current_user.can_delete_contracts ?

                                    {
                                        Header: "D",
                                        Cell: row => (
                                            <div class='iconedit'>
                                                <Icon name='delete'/>
                                                <span class="tooltiptext">Double Click to Delete</span>
                                            </div>),
                                        width: 30
                                    } :
                                    {
                                        Header: "",
                                        width: 1
                                    }
                                ,
                                {
                                    Header: "Id",
                                    accessor: "id",
                                    width: 1,
                                    style: {display: "none"}
                                },
                                {
                                    Header: "Number",
                                    accessor: "contract_no",
                                    width: 100

                                },
                                {
                                    Header: "Pri",
                                    accessor: "priority_d",
                                    width: 60

                                },
                                {
                                    Header: "Department",
                                    accessor: "contract_depts_d"
                                },
                                {
                                    Header: "Organization",
                                    accessor: "organization.name"
                                },
                                {
                                    Header: "Name",
                                    accessor: "name"
                                },
                                {
                                    Header: "Start Date",
                                    accessor: "start_date",
                                    width: 100,
                                    Cell: row => (
                                        <div>
                                            {row.value === null ? " " :
                                                <Moment format="MM/DD/YYYY">
                                                    {row.value}
                                                </Moment>
                                            }
                                        </div>
                                    )

                                }
                                ,
                                {
                                    Header: "End Date",
                                    accessor: "end_date",
                                    width: 100,
                                    Cell: row => (
                                        <div>
                                            {row.value === null ? " " :
                                                <Moment format="MM/DD/YYYY">
                                                    {row.value}
                                                </Moment>
                                            }
                                        </div>
                                    )
                                }
                                ,
                                {
                                    Header: "Status",
                                    accessor: "status",
                                    width: 100,
                                    Cell: row => (

                                        <div className={"status_" + row.value}>{row.value}</div>
                                    )

                                },
                                {
                                    Header: "Date Executed",
                                    accessor: "executed_date",
                                    Cell: row => (
                                        <div>
                                            {row.value === null ? " " :
                                                <Moment format="MM/DD/YYYY">
                                                    {row.value}
                                                </Moment>
                                            }
                                        </div>
                                    )
                                },
                                current_user.can_see_waiting_on ?

                                    {
                                        Header: "Waiting On",
                                        accessor: "last_action"
                                    } : {
                                        Header: "",
                                        width: 1
                                    },
                                {
                                    Header: "",
                                    accessor: "locked",
                                    width: 30,
                                    Cell: row => (
                                        <div>
                                            {row.value == true ? <Icon name="lock"/> : <Icon name="unlock"/>}
                                        </div>
                                    )
                                },
                                {
                                    Header: "",
                                    accessor: "flags",
                                    width: 30

                                }


                            ]}
                            showPaginationTop
                            manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                            data={data}
                            pages={pages} // Display the total number of pages
                            loading={loading} // Display the loading overlay when we need it
                            onFetchData={this.fetchData} // Request new data when things change
                            defaultPageSize={20}
                            className="-striped -highlight"
                />
            </div>


        );
    }
}

