/* eslint no-unused-vars: 0 */
import React, {Component} from 'react';
import global from "./global"
import Tab from 'semantic-ui-react/dist/es/modules/Tab';
import Menu from 'semantic-ui-react/dist/es/collections/Menu';
import Label from 'semantic-ui-react/dist/es/elements/Label';
import Button from 'semantic-ui-react/dist/es/elements/Button';
import Icon from 'semantic-ui-react/dist/es/elements/Icon';
import Confirm from 'semantic-ui-react/dist/es/addons/Confirm';
import {Form, Dropdown, Checkbox} from 'formsy-semantic-ui-react';
import Moment from "react-moment"
import Grid from 'semantic-ui-react/dist/es/collections/Grid';
import NumberFormat from 'react-number-format'
import MaskedInput from 'react-maskedinput'

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export class ExportEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: this.props.access_token,
            recid: this.props.recid,
            data: this.props.data,
            quarter: 0,
            year: 0,
            confirmOpen: false,
            columns: this.props.columns,
            parms: this.props.parms,
            end_date: "",
            start_date: "",
            downloading: false,
            current_user: JSON.parse(sessionStorage.getItem("current_user"))
        };


    }

    handleCancel = () => {
        this.setState({confirmOpen: false})
    }

    handleQuarterChange = (e, {value}) => {
        this.setState({
            quarter: value
        })
    }

    handleYearChange = (e, {value}) => {
        this.setState({
            year: value
        })
    }

    notifyError = (errors) => {
        toast.error(errors+"\n(click anywhere to close)",{autoClose: 10000})
    }
    notifySuccess = (msg) => {
        toast.success(msg, {autoClose: 3000})
    }
    handleSave = () => {
        var obj = JSON.parse(sessionStorage.getItem("current_user"));
        var comp = this;
        var parms = this.state.parms;
        var bad_date = false;
        var error = ""

        var start = new Date(this.state.start_date)
        var end = new Date(this.state.end_date)

        if(isNaN(start.getTime())) {
            bad_date = true;
            error += "Start Date is not a correct Date\n"
        }

        if(isNaN(end.getTime())) {
            bad_date = true;
            error += "End Date is not a correct Date\n"
        }

        

        if(this.props.path.includes('user')) {
            bad_date = true;
            error += "Export only works on All Contracts tab\n"
        }

        if(this.state.downloading) {
            this.notifyError("A export request is already in progress")
        } else {
            if(bad_date) {
                this.notifyError(error)
            }
            else if(start > end || bad_date) {
                this.notifyError("End Date cannot be before Start Date\n")
            } else {
                parms['start_date'] = start.toISOString().split('T')[0]
                parms['end_date'] = end.toISOString().split('T')[0]
                this.notifySuccess("Generating your document, please do not navigate away from this page")
                this.setState({downloading: true})
                global.getFetch(this.props.path + "_activity_excel", parms, this.state.access_token).then(function (resp) {
                    global.openLink(resp.xls)
                    comp.setState({downloading: false})
                })
            }
        }

    }
    markDirty = (currentValues, isChanged) => {
        this.setState({data: {
            month: currentValues.month,
            year: currentValues.year
        }})
    }
    endDateUpdate = (e) => {
        this.setState({end_date: e.target.value})
    }
    startDateUpdate = (e) => {
        this.setState({start_date: e.target.value})
    }

    render() {
        const {current_user} = this.state
        var quarters = [{text: "Jan-Mar", value: 1},{text: "Apr-June", value: 2},{text: "July-Sep", value: 3},{text: "Oct-Dec", value: 4}]
        var start_year_albany = 2025
        var today = new Date();
        var year = today.getFullYear()
        var years = [];
        for(let i = start_year_albany; i <= year; i++) {
            years.push({
                text: ""+ i,
                value: i
            })
        }

        const panes = [
            {
                menuItem: 'Main',
                pane: (
                    <Tab.Pane key='tab1'>

                    

                        <Form onChange={this.markDirty} ref="editForm" onSubmit={this.onValidSubmit}>
                            <Grid padded={false} columns='equal'>
                            
                            <Grid.Row>
                                <Grid.Column width={4}>
                                    <label><b>Start Date</b>&nbsp;<span style={{color: 'red'}}>*</span></label>
                                    <MaskedInput name="start_date"
                                                    onChange={this.startDateUpdate}
                                                    value={this.state.start_date}
                                                    mask="##/##/####"
                                    />
                                    <label><b>End Date</b>&nbsp;<span style={{color: 'red'}}>*</span></label>
                                    <MaskedInput name="end_date"
                                                    onChange={this.endDateUpdate}
                                                    value={this.state.end_date}
                                                    mask="##/##/####"
                                    />
                                </Grid.Column>
                                    
                            </Grid.Row>
                            </Grid>
                        </Form>
                    </Tab.Pane>

                )
            }
        ]
        return (
            <div>
                <Menu>
                    <Menu.Item>
                            <Button size="tiny" color="green" onClick={this.handleSave}>
                                Download
                            </Button>
                    </Menu.Item>

                </Menu>
                <br/>
                <Tab renderActiveOnly={false} panes={panes}/>
            </div>

        );
    }
}