import React, {Component} from 'react';
import global from "./global"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import UserFields from "./UserFields"
import Menu from 'semantic-ui-react/dist/es/collections/Menu';
import Button from 'semantic-ui-react/dist/es/elements/Button';
import Icon from 'semantic-ui-react/dist/es/elements/Icon';
import {Form} from 'formsy-semantic-ui-react';
import Tab from 'semantic-ui-react/dist/es/modules/Tab'
import Confirm from 'semantic-ui-react/dist/es/addons/Confirm'
import Label from 'semantic-ui-react/dist/es/elements/Label';
import Grid from 'semantic-ui-react/dist/es/collections/Grid'
import MaskedInput from 'react-maskedinput'
import Dropdown from 'semantic-ui-react/dist/es/modules/Dropdown'
import {stateOptions} from "./common"
export class VendorContactEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: this.props.access_token,
            recid: this.props.recid,
            data: this.props.data,
            dirtyFlag: this.props.dirtyFlag,
            confirmOpen: false,
            columns: this.props.columns,
            current_user: JSON.parse(sessionStorage.getItem("current_user")),
            user_fields: this.props.user_fields,
            vendor_id: this.props.vendor_id,
            addldata: {}


        };

    }
    NumberFieldUpdate = (e) => {
        var addldata = this.state.addldata
        addldata[e.target.name] = e.target.value
        this.setState({addldata: addldata})
        this.checkDirty(e.target)

    }
    handleEditorChange = (e) => {
        var vals = []
        vals.template = e
        this.checkDirty(vals)
        this.setState({data: {template: e}})
    }
    handleCancel = () => {
        this.setState({confirmOpen: false})
    }
    handleUndoConfirm = () => {
        this.setState({confirmOpen: false})
        this.props.loadRecord(this.state.recid)
    }
    undoRecord = () => {
        this.setState({confirmOpen: true})
    }
    notifyError = (errors) => {
        toast.error(errors + "\n(click anywhere to close)", {autoClose: 10000})
    }
    notifySuccess = (msg) => {
        toast.success(msg, {autoClose: 3000})
    }

    checkDirty(vals) {
        var savedData = this.state.data
        var comp = this
        Object.keys(vals).map(function (i) {
            if (vals[i] !== savedData[i]) {
                comp.props.dirtyFlag(true)
            }
            return false;

        })
    }

    markDirty = (currentValues, isChanged) => {
        this.checkDirty(currentValues)
    }
    setDirty = (val) => {
        this.isDirty = val
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            access_token: this.props.access_token,
            recid: this.props.recid,
            data: this.props.data,
            dirtyFlag: this.props.dirtyFlag,
            confirmOpen: false

        });
        this.refs.editForm.reset()
    }
    handleSave = () => {

        this.refs.vceditForm.submit()

    }

    onValidSubmit = (formData, resetForm, invalidateForm) => {
        var data = formData
        var addldata = this.state.addldata
        var comp = this
        var parms = {...data, ...addldata}
        parms.id = this.state.recid

         if (this.state.recid === null) {
            global.postFetch("vendors/contact/create", parms, this.state.access_token).then(function (resp) {
                if (resp.success === "Y") {
                    comp.notifySuccess(resp.message)
                    comp.props.dirtyFlag(false)
                    comp.props.closeModal()
                    comp.setState({
                        user_fields: resp.user_fields
                    })

                } else {
                    comp.notifyError(resp.message)
                    comp.props.dirtyFlag(true)
                
                }
            })
        } else {
            global.postFetch("vendors/contact/update", parms, this.state.access_token).then(function (resp) {
                if (resp.success === "Y") {
                    comp.notifySuccess(resp.message)
                    comp.props.closeModal()
                    comp.setState({
                        recid: resp.rec.id,
                        data: resp,
                        user_fields: resp.user_fields
                    })
                    comp.props.dirtyFlag(false)
                } else {
                    comp.notifyError(resp.message)
                    comp.props.dirtyFlag(true)
                }
            })
        }

    }

    render() {

        const panes2 = [
            {
                menuItem: 'Main',
                pane: (
                    <Tab.Pane key='tab1'>
                        <Form.Input
                            name="email"
                            label="Email"
                            placeholder="Email"
                            errorLabel={<Label color="red" pointing/>}
                            defaultValue={this.state.data.rec.email}
                            validationErrors={{
                                isDefaultRequiredValue: 'Email is Required',
                            }}/>
                        <Form.Input
                            name="vendor_id"
                            defaultValue={this.state.vendor_id}
                            style={{display: "none"}}
                            />
                        <Grid padded={false} columns='equal'>

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Input
                                        required
                                        name="last_name"
                                        label="Last Name"
                                        placeholder="Last Name"
                                        errorLabel={<Label color="red" pointing/>}
                                        defaultValue={this.state.data.rec.last_name}
                                        validationErrors={{
                                            isDefaultRequiredValue: 'Last Name is Required',
                                        }}/>

                                </Grid.Column>

                                <Grid.Column>

                                    <Form.Input
                                        required
                                        name="first_name"
                                        label="First Name"
                                        placeholder="First Name"
                                        errorLabel={<Label color="red" pointing/>}
                                        defaultValue={this.state.data.rec.first_name}
                                        validationErrors={{
                                            isDefaultRequiredValue: 'First Name is Required',
                                        }}/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>

                                    <Form.Input
                                        required
                                        name="title"
                                        label="Title"
                                        placeholder="Title"
                                        errorLabel={<Label color="red" pointing/>}
                                        defaultValue={this.state.data.rec.title}
                                        validationErrors={{
                                            isDefaultRequiredValue: 'Title is required',
                                        }}/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Input
                                        name="city"
                                        label="City"
                                        placeholder="City"
                                        defaultValue={this.state.data.rec.city}
                                    />

                                </Grid.Column>

                                <Grid.Column>

                                    <label><b>State</b></label>

                                    <Dropdown
                                        name="state"
                                        label="State"
                                        placeholder="Select State"
                                        search
                                        selection
                                        width={6}
                                        errorLabel={<Label color="red" pointing/>}
                                        defaultValue={this.state.data.rec.state}
                                        options={stateOptions}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Input
                                        name="zip"
                                        label="Zip"
                                        placeholder="Zip"
                                        defaultValue={this.state.data.rec.zip}
                                    />

                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>

                                    <label><b>Work Phone</b></label>
                                    <MaskedInput name="work_phone" onChange={this.NumberFieldUpdate}
                                                 value={this.state.data.rec.work_phone} mask="(###)###-####"
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <label><b>Alt Phone</b></label>
                                    <MaskedInput name="alt_phone" onChange={this.NumberFieldUpdate}
                                                 value={this.state.data.rec.alt_phone} mask="(###)###-####"
                                    />

                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                    </Tab.Pane>

                )
            }
        ]
        const panes=Object.assign(panes2,UserFields(this.state.user_fields,1,this.state.data.rec))

        return (
            <div>
                <Confirm
                    open={this.state.confirmOpen}
                    onCancel={this.handleCancel}
                    onConfirm={this.handleUndoConfirm}
                    content="Are you sure you want to undo changes?"
                    style={{fontSize: "1.2rem"}}

                />
                <Menu>
                    <Menu.Item>
                             <Button size="tiny" color="green" onClick={this.handleSave}>
                                <Icon name='save'/> Save
                            </Button>
                    </Menu.Item>

                </Menu>
                <br/>
                <Form onSubmit={this.onValidSubmit} onChange={this.markDirty} ref="vceditForm">

                <Tab renderActiveOnly={false} panes={panes}/>
                </Form>
            </div>

        );
    }
}


